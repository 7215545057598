export const RGBToHSL = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b), cmax = Math.max(r, g, b), delta = cmax - cmin;
    let h = 0, s = 0, l = 0;
    if (delta === 0) {
        h = 0;
    }
    else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    }
    else if (cmax === g) {
        h = (b - r) / delta + 2;
    }
    else {
        h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return `hsl(${h},${s}%,${l}%)`;
};
export const RGBToHSLFromString = (rgb) => {
    const sep = rgb.indexOf(",") > -1 ? "," : " ";
    rgb = rgb.substr(4).split(")")[0].split(sep);
    for (const R in rgb) {
        if (!rgb.hasOwnProperty(R)) {
            continue;
        }
        const r = rgb[R];
        if (r.indexOf("%") > -1) {
            rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
        }
    }
    const r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255;
    return RGBToHSL(r, g, b);
};
