import throttle from "lodash.throttle";
import { RGBToHSL } from "./colorHandling.utils";
const MAX_LEVELS_COLOR_PROBING = 20;
const DEFAULT_PAGE_SECTIONS_SELECTOR = "body>main>*, body>footer";
const CSS_CLASSNAME_PREFIX = "__header-theme-toggle_";
const CLASSNAME_CONTENT_CONTAINER_PREFIX = `${CSS_CLASSNAME_PREFIX}content-container`;
const CLASSNAME_TOGGLE_HEADER_PREFIX = `${CSS_CLASSNAME_PREFIX}change-header`;
const CLASSNAME_TOGGLE_HEADER_DARK = `${CLASSNAME_TOGGLE_HEADER_PREFIX}--dark`;
const CLASSNAME_TOGGLE_HEADER_LIGHT = `${CLASSNAME_TOGGLE_HEADER_PREFIX}--light`;
const CLASSNAME_HEADER_HEIGHT_SCROLLED = "has-header-height-scrolled";
const CLASSNAME_HEADER_DARK_THEME = "has-dark-header";
const CLASSNAME_HEADER_LIGHT_THEME = "has-light-header";
let intersectingSectionIndex = undefined;
let sections = undefined;
let bodyEl = undefined;
let headerEl = undefined;
let headerElBottomY = 0;
const isTransparentColor = (colorRgbValue) => {
    const transparentColorsValues = ["rgba(0, 0, 0, 0)"];
    if (transparentColorsValues.includes(colorRgbValue)) {
        return true;
    }
    return false;
};
const getNodeBgColor = (node) => {
    const bgColor = window.getComputedStyle(node)["background-color"];
    if (isTransparentColor(bgColor)) {
        return;
    }
    return bgColor;
};
const getSectionBgColor = (node, recursionLevelsCounter = 1) => {
    if (recursionLevelsCounter === MAX_LEVELS_COLOR_PROBING) {
        return;
    }
    const bgColor = getNodeBgColor(node);
    if (bgColor) {
        return bgColor;
    }
    const subNodes = node.childNodes;
    for (let i = 0; i < subNodes.length; i++) {
        const subNode = subNodes[i];
        if (subNode.nodeType !== 1) {
            continue;
        }
        return getSectionBgColor(subNode, recursionLevelsCounter + 1);
    }
    return;
};
const extractCSSSelectorsFromCustomString = (string) => {
    const replacementsMap = [
        { regExp: /\\000020/g, replacement: " " },
        { regExp: /\\\./g, replacement: "." },
        { regExp: /\\>/g, replacement: ">" },
        { regExp: /\\\*/g, replacement: "*" },
        { regExp: /\\:/g, replacement: ":" },
        { regExp: /\\,/g, replacement: "," }
    ];
    let cssSelector = string;
    replacementsMap.forEach(({ regExp, replacement }) => {
        cssSelector = cssSelector.replace(regExp, replacement);
    });
    return cssSelector;
};
const getClassListAsString = (classList) => [...classList].join(" ");
const getAnyNestedContentContainersHint = (classListString) => {
    if (!classListString) {
        return "";
    }
    const regExp = new RegExp(`${CLASSNAME_CONTENT_CONTAINER_PREFIX}___([^ ]+)`, "g");
    const possibleNestedContainerHints = classListString.matchAll(regExp);
    return [...possibleNestedContainerHints].map((hint) => hint[1]).join(",");
};
const getMatchingHeaderTheme = (sectionBgColor) => {
    if (!sectionBgColor) {
        return "light";
    }
    const rgbComponents = sectionBgColor.replace(/[^\d,.]/g, "").split(",");
    const hslColor = RGBToHSL(rgbComponents[0], rgbComponents[1], rgbComponents[2]);
    const hslComponents = hslColor.replace(/[^\d,.]/g, "").split(",");
    const lightnessRate = hslComponents.length && hslComponents[2] !== undefined
        ? hslComponents[2]
        : undefined;
    if (!lightnessRate || Number(lightnessRate) > 50) {
        return "light";
    }
    return "dark";
};
const checkShouldHeaderBeDark = (headerIntersectSectionIndex) => {
    if (!sections) {
        return;
    }
    const sectionEl = sections[headerIntersectSectionIndex].node;
    const elementCssClassList = sectionEl.classList;
    const shouldForceDarkHeader = elementCssClassList.contains(CLASSNAME_TOGGLE_HEADER_DARK);
    const shouldForceLightHeader = elementCssClassList.contains(CLASSNAME_TOGGLE_HEADER_LIGHT);
    return shouldForceDarkHeader || (!shouldForceLightHeader && getMatchingHeaderTheme(getSectionBgColor(sectionEl)) === "dark");
};
const manageHeaderTheme = (headerIntersectSectionIndex, isNewIntersection = true, isHeaderHeightScrolled = false) => {
    if (!sections ||
        !headerEl ||
        !bodyEl) {
        return;
    }
    bodyEl.classList.toggle(CLASSNAME_HEADER_HEIGHT_SCROLLED, isHeaderHeightScrolled);
    if (!isNewIntersection) {
        return;
    }
    const headerShouldBeDark = checkShouldHeaderBeDark(headerIntersectSectionIndex);
    bodyEl.classList.toggle(CLASSNAME_HEADER_DARK_THEME, headerShouldBeDark);
    bodyEl.classList.toggle(CLASSNAME_HEADER_LIGHT_THEME, !headerShouldBeDark);
};
const getPageSectionElements = () => {
    const sectionEls = document.querySelectorAll(DEFAULT_PAGE_SECTIONS_SELECTOR);
    if (!sectionEls?.length) {
        return;
    }
    const sectionElsArray = [...sectionEls].filter((sectionEl) => window.getComputedStyle(sectionEl)["display"] !== "none");
    sectionElsArray.forEach((sectionEl, index) => {
        const containerHints = getAnyNestedContentContainersHint(getClassListAsString(sectionEl.classList));
        const nestedSectionsCssSelector = extractCSSSelectorsFromCustomString(containerHints);
        let nestedSections;
        try {
            nestedSections =
                nestedSectionsCssSelector &&
                    sectionEl.querySelectorAll(`:scope ${nestedSectionsCssSelector}`);
        }
        catch (error) {
            console.debug("Error while querying for nested sections!:", error);
        }
        if (nestedSections?.length) {
            const displayedNestedSections = [...nestedSections].filter((sectionEl) => window.getComputedStyle(sectionEl)["display"] !== "none");
            if (displayedNestedSections?.length) {
                sectionElsArray.splice(index, 1, ...displayedNestedSections);
            }
        }
    });
    return sectionElsArray;
};
const setSections = () => {
    if (!bodyEl || !headerEl) {
        return;
    }
    const sectionEls = getPageSectionElements();
    if (!sectionEls?.length) {
        return;
    }
    sections = [...sectionEls].map((sectionEl) => ({
        node: sectionEl,
    }));
};
const handleIntersection = () => {
    if (!sections?.length) {
        return;
    }
    for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const { top: sectionTop, bottom: sectionBottom } = section.node.getBoundingClientRect();
        if (sectionTop <= headerElBottomY && sectionBottom > headerElBottomY) {
            const isHeaderHeightScrolled = window.scrollY > headerElBottomY;
            const isNewIntersection = intersectingSectionIndex !== i;
            manageHeaderTheme(i, isNewIntersection, isHeaderHeightScrolled);
            intersectingSectionIndex = i;
            return;
        }
    }
    intersectingSectionIndex = undefined;
};
const handleResize = () => {
    if (!headerEl) {
        return;
    }
    const { bottom: headerBottom } = headerEl.getBoundingClientRect();
    headerElBottomY = headerBottom;
    setSections();
    handleIntersection();
};
export const setupHeaderThemeToggling = () => {
    bodyEl = document.body;
    headerEl = bodyEl?.querySelector("header") || undefined;
    handleResize();
};
const throttledScrollHandler = throttle(handleIntersection, 100);
const throttledResizeHandler = throttle(handleResize, 100);
if (typeof window !== "undefined") {
    document.addEventListener("DOMContentLoaded", setupHeaderThemeToggling);
    window.addEventListener("resize", throttledResizeHandler);
    window.addEventListener("scroll", throttledScrollHandler);
}
